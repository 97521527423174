<template>
  <form @submit.prevent="saveProductTerm">
    <modal-card :title="'Product term'">
      <b-field>
        <b-switch v-model="form.isActive" :disabled="processing"
          >Term is active</b-switch
        >
      </b-field>

      <b-field label="Name">
        <b-input
          v-model="form.name"
          placeholder="Enter product name"
          :disabled="processing"
        />
      </b-field>

      <b-field label="Description">
        <textarea-autosize
          ref="editinput"
          v-model="form.description"
          placeholder="Please type product description here"
          rows="1"
          class="textarea has-margin-bottom-50"
          :min-height="30"
          :max-height="200"
          autosize
          :disabled="processing"
        />
      </b-field>

      <b-field label="Term length">
        <b-input
          v-model.number="form.termLength"
          type="number"
          min="0"
          :disabled="processing"
        />
      </b-field>

      <b-field label="Term saving">
        <b-input
          v-model="form.saving"
          placeholder="eg. 10%"
          :disabled="processing"
        />
      </b-field>

      <b-field>
        <b-switch v-model="form.isDiscountable" :disabled="processing"
          >Discounts can be applied</b-switch
        >
      </b-field>

      <b-field label="Term price">
        <table class="table is-bordered is-narrow is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th>
                Currency
              </th>
              <th>
                Setup price
              </th>
              <th>
                Term price
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(currency, index) in currencies" :key="index">
              <td>{{ currency.code }}</td>
              <td>
                <currency-input
                  :value="form.setupPrice[index]"
                  :prefix="currency.prefix"
                  :suffix="currency.suffix"
                  :disabled="processing"
                  step="0.01"
                  min="0 "
                  expanded
                  @input="form.setupPrice[index] = $_.round($event, 2)"
                />
              </td>
              <td>
                <currency-input
                  :value="form.termPrice[index]"
                  :prefix="currency.prefix"
                  :suffix="currency.suffix"
                  :disabled="processing"
                  step="0.01"
                  min="0"
                  expanded
                  @input="form.termPrice[index] = $_.round($event, 2)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </b-field>

      <button
        slot="footer"
        :class="{ 'is-loading': processing }"
        :disabled="!isValid || loading || processing || !hasChange"
        type="submit"
        class="button is-success"
      >
        Save
      </button>
    </modal-card>
  </form>
</template>

<script>
import { collection, doc, serverTimestamp, setDoc } from "@firebase/firestore";
const currencies = require("@/data/currencies.json");

export default {
  name: "ProductTermModal",
  props: {
    productId: {
      type: String,
      required: true
    },
    productTermId: {
      type: String,
      required: false,
      default: ""
    },
    editedProductTerm: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      loading: false,
      processing: false,
      term: {},
      form: {
        name: "",
        description: "",
        isActive: true,
        isDiscountable: true,
        type: "subscription",
        saving: "",
        setupPrice: {},
        termPrice: {},
        termLength: 1
      }
    };
  },
  computed: {
    currencies() {
      return currencies;
    },
    isValid() {
      const keys = this.$_.map(this.currencies, (c, idx) => idx);
      if (
        keys.filter(
          c =>
            this.$_.isNumber(this.form.termPrice[c]) &&
            this.form.termPrice[c] &&
            this.form.termPrice[c] > 0 &&
            this.form.setupPrice[c] >= 0
        ).length !== this.$_.toArray(currencies).length
      )
        return false;
      if (this.$_.get(this.form, "termLength", 0) < 0) return false;
      if (this.$_.isEmpty(this.form.name)) return false;
      if (this.$_.isEmpty(this.form.description)) return false;

      return true;
    },
    hasChange() {
      return !this.$_.isEqual(this.form, this.$_.omit(this.term, ["_id"]));
    }
  },
  created() {
    this.setAllCurrencies();
    this.form = this.$_.merge({}, this.form, this.editedProductTerm);
    this.term = this.$_.merge({}, this.form, this.editedProductTerm);
  },
  methods: {
    setAllCurrencies() {
      this.$_.forEach(currencies, (i, index) => {
        this.$_.set(this.form.setupPrice, index, 0);
        this.$_.set(this.form.termPrice, index, 0);
      });
    },
    saveProductTerm() {
      this.processing = true;
      let form = this.form;

      let termsCollection = collection(
        this.$firestore,
        `products/${this.productId}/terms`
      );
      let termRef = doc(termsCollection);

      if (!this.$_.isEmpty(this.productTermId)) {
        form.dateUpdated = serverTimestamp();
        termRef = doc(termsCollection, this.productTermId);
      } else {
        form.dateCreated = serverTimestamp();
      }

      setDoc(termRef, form)
        .then(() => {
          this.processing = false;
          this.$emit("success");
          this.$toast.open({
            message: `Product term saved`
          });
        })
        .catch(() => {
          this.processing = false;
          this.$toast.open({
            message: `Error saving product term`,
            type: "is-danger"
          });
        });
    }
  }
};
</script>
